exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-js": () => import("./../../../src/pages/accounting.js" /* webpackChunkName: "component---src-pages-accounting-js" */),
  "component---src-pages-customers-employees-js": () => import("./../../../src/pages/customers-employees.js" /* webpackChunkName: "component---src-pages-customers-employees-js" */),
  "component---src-pages-dailyscrum-js": () => import("./../../../src/pages/dailyscrum.js" /* webpackChunkName: "component---src-pages-dailyscrum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-features-js": () => import("./../../../src/pages/other-features.js" /* webpackChunkName: "component---src-pages-other-features-js" */),
  "component---src-pages-scrumboard-js": () => import("./../../../src/pages/scrumboard.js" /* webpackChunkName: "component---src-pages-scrumboard-js" */),
  "component---src-pages-time-management-js": () => import("./../../../src/pages/time-management.js" /* webpackChunkName: "component---src-pages-time-management-js" */)
}

